<mat-form-field appearance="outline" floatLabel="always" class="site-selector">
  <!--mat-label>{{ 'sdp.list.site' | translate}}</mat-label-->
  <mat-select
    [class.loading-field]="loadingSites || (loadingOrgs$ | async)"
    data-automation="siteSelector"
    id="siteSelector"
    [(ngModel)]="selectedSiteId"
    [disabled]="sites.length == 0"
    [placeholder]="(sites.length > 0 ? 'sdp.list.site' : 'sdp.list.no_sites') | translate"
    (selectionChange)="loadSdps($event)"
  >
    <ng-container *ngFor="let site of sites; let i = index">
      <mat-option [value]="site.id">{{ site.displayLabel }}</mat-option>
    </ng-container>
  </mat-select>
  <mat-spinner class="spinner" *ngIf="loadingSites || (loadingOrgs$ | async)" matSuffix diameter="20"></mat-spinner>
</mat-form-field>

<div class="line-divider"></div>

<mat-list
  *ngIf="!(loadingSDPs || loadingSites) && siteSelected && !noSDPs"
  data-automation="selectedSDP"
  class="listHeader sdps-list"
>
  <mat-list-item
    *ngFor="let SDP of spdList"
    [ngClass]="{ selectedSDP: SDP.id === selectedSDPId }"
    [attr.data-automation]="'sdp-item-' + SDP.id"
    (click)="onRowClicked(SDP.id)"
  >
    <mat-icon matListIcon [ngSwitch]="getServiceDeliveryPointStatus(SDP)">
      <div
        *ngSwitchCase="'ACTIVE'"
        class="status-icon status-icon-active"
        title="{{ 'sdp.list.status.active' | translate }}"
      ></div>
      <div
        *ngSwitchCase="'INACTIVE'"
        class="status-icon status-icon-inactive"
        title="{{ 'sdp.list.status.inactive' | translate }}"
      ></div>
    </mat-icon>
    <h3 matLine>{{ SDP.displayLabel }}</h3>
    <p matLine>{{ getSDPActiveUtilityAccount(SDP.id) }}</p>
  </mat-list-item>
</mat-list>

<ng-container *ngIf="!(loadingSDPs || loadingSites)" data-automation="drawer-message">
  <div *ngIf="noSDPs && siteSelected && !errorLoadingSDPs.hasError" data-automation="drawer-message" class="list-empty">
    {{ 'sdp.list.none_configured' | translate }}
  </div>
  <div *ngIf="!siteSelected && sites.length > 0" data-automation="drawer-message" class="list-empty">
    {{ 'sdp.list.no_site_selected' | translate }}
  </div>
  <div *ngIf="errorLoadingSDPs.hasError" data-automation="drawer-message" class="error-message">
    <img class="warn-icon" src="./assets/svg/warning-icon.svg" alt="" />{{ errorLoadingSDPs.message }}
  </div>
</ng-container>

<div class="spinner-container" *ngIf="loadingSites || loadingSDPs">
  <mat-spinner mode="indeterminate" diameter="60" color="primary"></mat-spinner>
</div>

<div class="create-button-container">
  <button
    (click)="routeToCreatePage()"
    id="create-button"
    data-automation="create-button"
    class="icon-btn x-btn-primary"
  >
    <mat-icon>add</mat-icon>
    <span class="btn-text">{{ 'sdp.list.button.create_service_delivery_point' | translate }}</span>
  </button>
</div>
