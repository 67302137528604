import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalAlertService } from '../shared/services/global-alert.service';
import { ServiceDeliveryPointService } from '../shared/services/service-delivery-points.service';
import { NgxDeeplinkerService } from 'ngx-deeplinker';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DeleteDialogComponent } from '../dialogs/delete/delete-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { ServiceDeliveryPoint } from '../shared/model/service-delivery-point.model';
import { Locale } from '../shared/model/locale.model';
import { OrganizationsService } from '../shared/services/organizations.service';
import { SiteService } from '../shared/services/sites.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss', '../shared/shared.styles.scss'],
})
export class EditComponent implements OnInit, OnDestroy {
  SUCCESS = 'Created Successfully';
  BAD_REQUEST = 'Oops, There was a problem with your request';
  NOT_UPDATED = 'Oops, There was a problem updating your sdp';
  REQUIRED = 'required';
  CONFLICT = 'There is a data conflict with this ServiceDeliveryPoint';

  serviceDeliveryPointId = '';
  subscriptions = [];
  isSubmitting = false;
  isLoadingAsset = false;
  selectedSite = null;
  currentSite = null;
  serviceDeliveryPointForm: FormGroup = new FormGroup({
    id: new FormControl('', Validators.required),
    displayLabels: new FormControl('', Validators.required),
    supportedLocales: new FormControl([new Locale()]),
    descriptions: new FormControl(''),
    defaultLocale: new FormControl('', Validators.required),
    timezone: new FormControl('America/New_York', Validators.required),
    status: new FormControl('ACTIVE', Validators.required),
    siteId: new FormControl('', Validators.required),
    commodity: new FormControl('', Validators.required),
    startDttm: new FormControl(new Date(), Validators.required),
    endDttm: new FormControl(null),
    sdpType: new FormControl('UTILITY', Validators.required),
    utilityMeterNumber: new FormControl(''),
  });
  readonly mode = 'edit';
  readonly APPPREFIX = 'prt';
  serviceDeliveryPoint: ServiceDeliveryPoint;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private messageService: GlobalAlertService,
    private serviceDeliveryPointService: ServiceDeliveryPointService,
    private ngxDeeplinkerService: NgxDeeplinkerService,
    private translateService: TranslateService,
    private organizationsService: OrganizationsService,
    public dialog: MatDialog,
    private siteService: SiteService,
  ) {
    if (this.organizationsService.isInit) {
      this.organizationsService.getOrgs();
    }
    this.translateService
      .get('service.delivery.account.notification.updated_successfully')
      .subscribe((result: string) => {
        this.SUCCESS = result;
        this.BAD_REQUEST = this.translateService.instant('service.delivery.account.notification.bad_request');
        this.NOT_UPDATED = this.translateService.instant('service.delivery.account.notification.not_updated');
        this.REQUIRED = this.translateService.instant('service.delivery.account.validation.required');
        this.CONFLICT = this.translateService.instant('service.delivery.account.notification.conflict');
      });

    this.serviceDeliveryPointService.conflictError.subscribe(() => {
      this.messageService.setError(this.CONFLICT, 7000);
    });
  }

  ngOnInit() {
    const routeSub = this.route.params.subscribe(async params => {
      if (params.id) {
        this.serviceDeliveryPointId = params.id;
        this.isLoadingAsset = true;
        this.serviceDeliveryPointService.selectedServiceDeliveryPointId$.next(this.serviceDeliveryPointId);
        this.serviceDeliveryPointService.getServiceDeliveryPoint(params.id);
        this.isLoadingAsset = false;
        this.siteService.setIsFirst(true);
      }
    });

    const serviceDeliveryPointSub = this.serviceDeliveryPointService.serviceDeliveryPoint$.subscribe(
      async serviceDeliveryPoint => {
        if (serviceDeliveryPoint) {
          this.serviceDeliveryPoint = serviceDeliveryPoint;
          this.serviceDeliveryPoint.commodity = this.serviceDeliveryPoint.commodity.toUpperCase();
          this.serviceDeliveryPointForm.patchValue(serviceDeliveryPoint);
        }
      },
    );

    const selectedSiteSub = this.siteService.selectedSite$.subscribe(async selectedSite => {
      if (selectedSite) {
        this.selectedSite = selectedSite;
        this.serviceDeliveryPointForm.patchValue(this.serviceDeliveryPoint);
      }
    });

    this.subscriptions = [routeSub, serviceDeliveryPointSub, selectedSiteSub];
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  async handleSubmit() {
    if (!this.serviceDeliveryPointForm.valid) {
      this.messageService.setError(this.REQUIRED);
    } else {
      try {
        let sdpTransformBody;
        let currentSite;
        this.isSubmitting = true;
        const sdp = this.serviceDeliveryPointForm.getRawValue();
        const siteId = sdp.siteId;
        const parent = this.serviceDeliveryPointService.getSdpParentId();
        const sitesSub = this.siteService.sites$.subscribe(sites => {
          currentSite = sites.filter(site => site.id === siteId);
        });
        sdpTransformBody = {
          ...sdp,
          address1: currentSite[0].address1,
          address2: currentSite[0].address2,
          city: currentSite[0].city,
          name: sdp.displayLabels[sdp.defaultLocale],
          country: currentSite[0].country,
          postal_code: Number(currentSite[0].postalCode),
          state: currentSite[0].state,
          timezone: currentSite[0].timezone,
        };
        const response = await this.serviceDeliveryPointService.updateServiceDeliveryPoint(
          { ...sdpTransformBody },
          siteId,
          parent,
        );
        this.ngxDeeplinkerService.returnHandler({ appPrefix: this.APPPREFIX, callbackValue: response.id });
        this.messageService.setSuccess(this.SUCCESS);
        setTimeout(async () => {
          const shouldUpdateList = true;
          const forceSelectFirstSite = false;
          await lastValueFrom(this.serviceDeliveryPointService.getServiceDeliveryPoints(siteId, shouldUpdateList, forceSelectFirstSite));
          this.siteService.selectSite(siteId);
          await this.router.navigate([`details/${response.id}/view`], {});
        }, 2000);
        this.subscriptions.push(sitesSub);
      } catch (e) {
        console.log(e);
        let errorMessage = '';
        if (e.error && e.error.message) {
          errorMessage = e.error.message;
        } else {
          errorMessage = 'ERR_BAD_REQUEST';
        }
        if (errorMessage === 'ERR_BAD_REQUEST') {
          this.messageService.setError(this.NOT_UPDATED);
        } else {
          this.messageService.setError(this.NOT_UPDATED);
        }
      }
      this.isSubmitting = false;
    }
  }

  openDeleteDialog(): void {
    this.dialog.open(DeleteDialogComponent, {
      width: '400px',
      data: {
        assetId: this.serviceDeliveryPointId,
      },
    });
  }

  handleCancel() {
    this.router.navigate([`details/${this.serviceDeliveryPointId}/view`]);
  }
}
