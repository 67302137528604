import { validate } from 'uuid';

export function resetUUID(obj: unknown): unknown {
  if (Array.isArray(obj)) {
    // If the input is an array, process each element
    return obj.map(item => resetUUID(item));
  }

  if (typeof obj !== 'object' || obj === null) return obj;

  return Object.entries(obj).reduce((acc, [key, value]) => {
    let newKey = key;

    // Check if the key is a valid UUID (even without dashes)
    const uuidWithoutDashes = key.replace(/-/g, '');
    if (
      uuidWithoutDashes.length === 32 &&
      validate(uuidWithoutDashes.replace(/(.{8})(.{4})(.{4})(.{4})(.{12})/, '$1-$2-$3-$4-$5'))
    ) {
      newKey = uuidWithoutDashes.replace(/(.{8})(.{4})(.{4})(.{4})(.{12})/, '$1-$2-$3-$4-$5');
      newKey = newKey.toLowerCase();
    }

    // Recursively process nested objects or arrays
    acc[newKey] = resetUUID(value);
    return acc;
  }, {});
}
