import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { toCamel, toSnake } from 'convert-keys';
import { catchError, map, Observable, throwError } from 'rxjs';
import { resetUUID } from '@helper';
import { RequestMethodEnum, RequestOptions } from '@model';

@Injectable({ providedIn: 'root'})
export class GenericService {
  static HEADERS: HttpHeaders;
  static BASE_ENDPOINT = '/api/v1';

  constructor(private httpClient: HttpClient) {}

  /**
   * An alert is displayed everytime there is an error.
   * Alert title is the error code.
   * If there is a translation key the message is taken from translation file,
   * otherwise is set the API error message
   *
   * @param error the error got from the API
   */
  static handleError(error: unknown): Observable<never> {
    return throwError(() => new Error(`${error}`));
  }

  /**
   * The public method used to fetch an API.
   * Response is already mapped and an error handler is present
   * @param options contains the info to fetch the api
   */
  request<TReq, TRes>(options: RequestOptions<TReq, TRes>): Observable<TRes> {
    let call$: Observable<TRes>;
    const path = `${GenericService.BASE_ENDPOINT}${options.path}`;
    let body: TReq;
    if (options.body) {
      body = resetUUID(toSnake(options.body)) as TReq;
    }
    switch (options.method) {
      case RequestMethodEnum.GET: {
        call$ = this.get<TRes>(path, options.params);
        break;
      }
      case RequestMethodEnum.POST: {
        call$ = this.post<TReq, TRes>(path, body, options.params);
        break;
      }
      case RequestMethodEnum.DELETE: {
        call$ = this.delete<TReq, TRes>(path, body, options.params);
        break;
      }
    }
    return call$.pipe(
      map(item => this.mapResponse(item, options.fullResponse, options.mappingCallback)),
      catchError((error: unknown) => GenericService.handleError(error)),
    );
  }

  private get<TRes>(path: string, params?: HttpParams): Observable<TRes> {
    return this.httpClient.get<TRes>(path, {
      headers: GenericService.HEADERS,
      params,
    });
  }

  private post<TReq, TRes>(path: string, body: TReq, params?: HttpParams): Observable<TRes> {
    return this.httpClient.post<TRes>(path, body, {
      headers: GenericService.HEADERS,
      params,
    });
  }

  private delete<TReq, TRes>(path: string, body: TReq, params?: HttpParams): Observable<TRes> {
    return this.httpClient.delete<TRes>(path, {
      body,
      headers: GenericService.HEADERS,
      params,
    });
  }

  /**
   * Function that maps all property into camelCase notation.
   *
   * @param item the whole response object
   * @param fullResponse if true the whole object is returned, if false only the data
   * @param mappingCallback a callback function to customize the response
   */
  private mapResponse<TRes>(
    item: unknown,
    fullResponse = false,
    mappingCallback: ((response: unknown) => TRes) | undefined,
  ): TRes {
    if (!item) {
      return item as TRes;
    }
    if (+item['code'] > 399) {
      throw new Error(`${item['code']} - ${item['status']} - ${item['message']}`);
    }
    const data = fullResponse ? item : item['data'];
    if (!mappingCallback) {
      if (typeof data !== 'object') {
        return data;
      }
      return resetUUID(toCamel(data)) as TRes;
    }

    return resetUUID(toCamel(mappingCallback(data))) as TRes;
  }
}
