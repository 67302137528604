import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxDeeplinkerService } from 'ngx-deeplinker';
import { GlobalAlertService } from '../shared/services/global-alert.service';
import { TranslateService } from '@ngx-translate/core';
import { Locale } from '../shared/model/locale.model';
import { ServiceDeliveryPointService } from '../shared/services/service-delivery-points.service';
import { OrganizationsService } from '../shared/services/organizations.service';
import { SiteService } from '../shared/services/sites.service';
import { lastValueFrom, Subscription } from 'rxjs';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss', '../shared/shared.styles.scss'],
})
export class CreateComponent {
  SUCCESS = 'Created Successfully';
  BAD_REQUEST = 'Oops, There was a problem with your request';
  NOT_CREATED = 'Oops, There was a problem creating your service delivery point';
  REQUIRED = 'required';

  isSubmitting = false;

  serviceDeliveryPointForm: FormGroup = new FormGroup({
    displayLabels: new FormControl({}, Validators.required),
    defaultLocale: new FormControl('en_US', Validators.required),
    supportedLocales: new FormControl([new Locale()]),
    commodity: new FormControl('', Validators.required),
    status: new FormControl('ACTIVE', Validators.required),
    sdpType: new FormControl('UTILITY', Validators.required),
    descriptions: new FormControl(''),
    siteId: new FormControl('', Validators.required),
    startDttm: new FormControl(new Date(), Validators.required),
    endDttm: new FormControl(null),
    utilityMeterNumber: new FormControl(''),
  });
  readonly APPPREFIX = 'prt';
  readonly mode = 'create';
  private subscriptions: Subscription[] = [];

  constructor(
    private ngxDeeplinkerService: NgxDeeplinkerService,
    private router: Router,
    private messageService: GlobalAlertService,
    private serviceDeliveryPointService: ServiceDeliveryPointService,
    private translateService: TranslateService,
    private organizationsService: OrganizationsService,
    private siteService: SiteService,
  ) {
    if (this.organizationsService.isInit) {
      this.organizationsService.getOrgs();
    }
    this.serviceDeliveryPointService.selectedServiceDeliveryPointId$.next('');
    this.translateService.get('utility.account.notification.created_successfully').subscribe((result: string) => {
      this.SUCCESS = result;
      this.BAD_REQUEST = this.translateService.instant('utility.account.notification.bad_request');
      this.NOT_CREATED = this.translateService.instant('utility.account.notification.not_created');
      this.REQUIRED = this.translateService.instant('utility.account.validation.required');
    });
  }

  handleCancel() {
    this.ngxDeeplinkerService.returnHandler({ appPrefix: this.APPPREFIX });
    this.router.navigate(['/']);
  }

  async handleSubmit() {
    if (!this.serviceDeliveryPointForm.valid) {
      this.messageService.setError(this.REQUIRED);
    } else {
      try {
        let sdpTransformBody;
        this.isSubmitting = true;
        const sdp = this.serviceDeliveryPointForm.getRawValue();
        const siteId = sdp.siteId;
        delete sdp.siteId;
        const sitesSub = this.siteService.sites$.subscribe(sites => {
          const currentSite = sites.filter(site => site.id === siteId);
          return (sdpTransformBody = {
            ...sdp,
            address1: currentSite[0].address1,
            address2: currentSite[0].address2,
            city: currentSite[0].city,
            name: sdp.displayLabels[sdp.defaultLocale],
            country: currentSite[0].country,
            postal_code: currentSite[0].postalCode,
            state: currentSite[0].state,
            timezone: currentSite[0].timezone,
          });
        });
        const response = await this.serviceDeliveryPointService.createServiceDeliveryPoint(
          { ...sdpTransformBody },
          siteId,
        );
        this.ngxDeeplinkerService.returnHandler({ appPrefix: this.APPPREFIX, callbackValue: response.id });
        this.messageService.setSuccess(this.SUCCESS);
        setTimeout(async () => {
          const shouldUpdateList = true;
          const forceSelectFirstSite = false;
          await lastValueFrom(this.serviceDeliveryPointService.getServiceDeliveryPoints(siteId, shouldUpdateList, forceSelectFirstSite));
          this.siteService.selectSite(siteId);
          await this.router.navigate([`details/${response.id}/view`], {});
        }, 2000);
        this.subscriptions.push(sitesSub);
      } catch (e) {
        console.log(e);
        if (e) {
          const errorMessage = e.error?.message || e.message;
          if (errorMessage === 'ERR_BAD_REQUEST') {
            this.messageService.setError(this.BAD_REQUEST);
          } else {
            this.messageService.setError(this.NOT_CREATED);
          }
        }
      }
      this.isSubmitting = false;
    }
  }
  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }
}
