/* * * ANGULAR * * */
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { CommonModule, registerLocaleData } from '@angular/common';

/* * * 3RD PARTY * * */
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

/* * * SHARED * * */
import { NgxGlobalContactFooterModule } from 'ngx-global-contact-footer';
import { GlobalNavModule } from 'ngx-global-nav';

import { NgxDeeplinkerModule } from 'ngx-deeplinker';
import { TreeModule  } from 'enel-tree';
import { AnalyticsService, CookieService as NgxCookieService, NOC_ANALYTICS } from 'ngx-shared-services';
import { analytics } from 'noc-analytics';
import { CookieService } from './shared/services/cookie.service';
import { GlobalAlertService } from './shared/services/global-alert.service';
import { GlobalAlertModule } from './global_alert/global-alert.module';
import { MultiLocaleModule } from 'multi-locale-input';

import { AuthHttpInterceptor } from './services/auth.interceptor';
import { MockHttpInterceptor } from './services/mock.interceptor';

/* * * COMPONENTS * * */
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CreateComponent } from './create/create.component';
import { ListComponent } from './list/list.component';
import { EditComponent } from './edit/edit.component';
import { ServiceDeliveryPointFormComponent } from './service_delivery_point_form/service-delivery-point-form.component';
import { DeleteDialogComponent } from './dialogs/delete/delete-dialog.component';
import { ViewComponent } from './view/view.component';
import { DefaultComponent } from './default/default.component';

import { ApiService } from './shared/services/api.service';
import { DeeplinksService } from './shared/services/deeplinks.service';
import { LocalesService } from './shared/services/locales.service';
import { StatusesService } from './shared/services/statuses.service';
import { TimezonesService } from './shared/services/timezones.service';
import { SidenavService } from './shared/services/sidenav.service';
import { SelectedSpaceService } from './shared/services/selected-space.service';
import { NavService } from './shared/services/global-nav.service';

/* * * MATERIAL * * */
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MatMomentDateModule,
} from '@angular/material-moment-adapter';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';

import localeIt from '@angular/common/locales/it';
import localeZh from '@angular/common/locales/zh';
import localePl from '@angular/common/locales/pl';
import localeJa from '@angular/common/locales/ja';
import localeNb from '@angular/common/locales/nb';
import { SiteService } from './shared/services/sites.service';
import { OrganizationsService } from './shared/services/organizations.service';
import { TypeService } from './shared/services/types.service';
import { CommoditiesService } from './shared/services/commodities.service';
import { UtilityAccountsService } from './shared/services/utility-accounts.service';

registerLocaleData(localeIt, 'it-IT');
registerLocaleData(localeZh, 'zh-TW');
registerLocaleData(localePl, 'pl-PL');
registerLocaleData(localeJa, 'ja-JP');
registerLocaleData(localeNb, 'nb-NO');

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    DefaultComponent,
    ListComponent,
    CreateComponent,
    EditComponent,
    ServiceDeliveryPointFormComponent,
    DeleteDialogComponent,
    ViewComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    BrowserAnimationsModule,
    GlobalNavModule.forRoot({ namespace: 'ngx-global-nav.' }),
    NgxGlobalContactFooterModule.forRoot({ namespace: 'ngx-global-contact-footer.' }),
    GlobalAlertModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    RouterModule.forRoot([]),
    AppRoutingModule,
    MatFormFieldModule,
    MatSelectModule,
    MatTabsModule,
    MatTableModule,
    MatInputModule,
    NgxDeeplinkerModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatIconModule,
    MatCheckboxModule,
    TreeModule,
    MatButtonModule,
    MatDialogModule,
    MatSidenavModule,
    MultiLocaleModule,
    ReactiveFormsModule,
    MatListModule,
  ],
  providers: [
    TranslateService,
    AnalyticsService,
    NgxCookieService,
    CookieService,
    { provide: HTTP_INTERCEPTORS, useClass: MockHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    HttpClient,
    ApiService,
    GlobalAlertService,
    StatusesService,
    TimezonesService,
    LocalesService,
    DeeplinksService,
    SelectedSpaceService,
    MatDatepickerModule,
    SidenavService,
    SiteService,
    NavService,
    TypeService,
    CommoditiesService,
    OrganizationsService,
    UtilityAccountsService,
    {
      provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
      useValue: {
        useUtc: false,
        strict: true,
      },
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    { provide: NOC_ANALYTICS, useValue: analytics },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
