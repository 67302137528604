import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CookieService } from './cookie.service';
import * as convertKeys from 'convert-keys';
import { AtlasPagesService } from 'atlas-pages-loader';
import { UtilityAccount } from '../model/utility-account';

@Injectable()
export class UtilityAccountsService {
  isInit = true;
  readonly serviceAccounts$ = new BehaviorSubject<UtilityAccount[]>([]);
  loadingServiceAccounts$ = new BehaviorSubject<boolean>(null);
  pagesLoaderService: AtlasPagesService;

  constructor(private apiService: ApiService, private httpClient: HttpClient, cookieService: CookieService) {
    this.pagesLoaderService = new AtlasPagesService(
      cookieService.getMarketsMock(),
      cookieService.getEnocSession(),
      this.httpClient
    );
  }

  async getServiceAccounts() {
    if (this.isInit) {
      try {
        this.loadingServiceAccounts$.next(true);
        const { types: serviceAccounts } = await this.pagesLoaderService.getAllPagesForType('serviceaccount');
        this.serviceAccounts$.next(convertKeys.toCamel(serviceAccounts));
        this.loadingServiceAccounts$.next(false);
      } catch (err) {
        this.loadingServiceAccounts$.next(false);
        console.log(`Could not load Service Accounts.`, err);
      }
    }
    this.isInit = false;
  }
}
